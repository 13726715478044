import styled from 'styled-components';


const BackgroundImage = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
    bottom:0;

    ${({ theme }) => theme.media.phone}{
        background-image: ${({ image }) => `url(${image})`};
    }
`

export default BackgroundImage;